<template>
  <div class="home">
    <section class="home-top" v-observe-visibility="visibilityChanged">
      <div class="wrapper">
        <img alt="circle" class="circle" src="@/assets/home/circle.svg" />
        <img alt="circle-2" class="circle-2" src="@/assets/home/circle-2.svg" />
        <img
          alt="ribbon"
          class="right-ribbon"
          src="@/assets/home/right-ribbon.svg"
        />
        <img
          alt="ribbon"
          class="left-ribbon"
          src="@/assets/home/left-ribbon.svg"
        />
        <transition name="slide-top" appear>
          <header v-if="visible">
            <img alt="hr logo" src="@/assets/home/logo.svg" />
            <Button @click="scrollTo('form')">СВЪРЖЕТЕ СЕ С НАС</Button>
          </header>
        </transition>
        <transition name="appear" appear>
          <main class="home-top-content" v-if="visible">
            <div class="text-box">
              <h1>
                Различен от всеки друг <strong>HR инструмент</strong> който сте
                използвали преди
              </h1>
              <img alt="monitor" src="@/assets/home/monitor.png" />
              <p>
                Твърде много време се прекарва в електронни таблици. С HR
                Digital ще централизирате данните за множество процеси на едно
                място, за да отключите набор от мощни автоматизации и справки
              </p>
            </div>
            <div class="monitor-div">
              <img alt="monitor" src="@/assets/home/monitor.png" />
            </div>
          </main>
        </transition>

        <transition name="slide-bottom" appear>
          <div class="learn-more" v-if="visible">
            <h1>Разберете какво може да правите с HR Digital</h1>
            <img alt="monitor" src="@/assets/home/scroll.svg" />
            <Button @click="scrollTo('form')">СВЪРЖЕТЕ СЕ С НАС</Button>
          </div>
        </transition>
      </div>
    </section>

    <section class="home-mid">
      <ImageText
        h1="Графици на служители и смени"
        fp="Управление на групови графици, индивидуални смени, разместване с един
          клик. Ефективно определяте групите работници по обекти/заводи/смени,
          компенсации и почивни дни. "
        sp="Резултатът от присъствията и отсъствията се
          записва в автоматично генерирана Форма 76 (присъствен лист) онлайн
          или в ексел - по Ваше желание."
        tp="Автоматично отчитане на наряди,
          планиране и осчетоводяване."
        img="1.png"
        color="#fce9d5"
      />
      <ImageText
        h1="Почасови отсъствия"
        fp="Служителите са хоум офис, трябва да излязат за час или два, половин ден,
         да се компенсират с допълнителна почивка или се налага служебно напускане на
         работното място?"
        sp="Всичко вече е видимо и се смята автоматично. Заявление, одобрение, 
         напускане, връщане, сумиране на всички отсъствия по тип и вид – следствие на
         което се изчислява отпуск, компенсация, друго… пълен контрол над работното време
         и работен процес."
        img="2.png"
        right="true"
        color="#EDFBE8"
      />

      <ImageText
        h1="Електронно трудово досие"
        fp="Край на ежедневните хартиени молби, служебни бележки, анекси и бланкови документи.
         Всичко е електронно – служител от своя телефон, компютър, устройство с интернет пуска и подписва
         електронно своята молба, доклад, заповед – документа се движи по процедурата без човешка намеса.
         Подписани чрез КЕП, УЕП, или двустранна верификация – вече имате: 100% защита на документите,
         100% контрол над електронното трудово досие и по-малко хартиено базирани процеси – 100% съвместимо
         с наредбите на държавната администрация."
        sp="Всичко се пази електронно и достъпно според 
         правата за достъп от всяка точка на света 24/7."
        img="3.png"
        color="#C6F3F8"
      />
      <ImageText
        h1="Електронни отпуски"
        fp="100% премахване на хартиени носители, бланки, подписи. 100% премахване на грешки при пускане на отпуск.
         Всички видове отпуск и съответните бланки за тях са в дигитален формат, като служителите могат да ги
         пуснат от всяко устройство."
        sp="Одобрение на отпуск на няколко нива според йерархията, управление на заместващи
         служители и автоматично осчетоводяване на отпуските – интеграция с всеки софтуер на база excel, csv,
         автоматични импорти и експорт."
        img="4.png"
        right="true"
        color="#D1D5FA"
      />
      <ImageText
        h1="Присъствия и присъствени форми (Форма 76)"
        fp="Отчитането на работното време никога не е било по-лесно. На база графици, присъствия, отсъствия, компенсации,
         система за контрол на достъп – модула автоматично създава присъствените листи и генерира Форма 76. Бързо, ясно и
         отчетливо за всеки отдел, група, компания, служител."
        sp="Всяка форма е в различен формат според типа и
         нуждата на компанията."
        img="5.png"
        color="#FCE9D5"
      />
      <ImageText
        h1="Хоум офис и отчет на дейности отдалечено"
        fp="Пълен контрол над отдалечената работа (Home Office), отчет на задачите извършени отдалечено по приоритети, проекти,
         направления."
        sp="Вече имате контрол над извършваните дейности за служителите работещи хоум офис – как, кога и за колко
         време изпълняват своите задачи."
        img="6.png"
        right="true"
        color="#EDFBE8"
      />
      <ImageText
        h1="Електронно обучение (в процес)"
        fp="Начален инструктаж, влизане в предприятието, встъпителни изпити за започване на работа, обучение на кадри за започване
         на работа, квалификация, допълнителна квалификация – всичко става интерактивно."
        sp="Лесно създаване, показване, усвояване на знания 
         свързани с работния процес и процедурите по Безопасни Условя на Труд."
        img="7.png"
        color="#C6F3F8"
      />
      <ImageText
        h1="Процедури по БЗР и ЗБУТ (в процес)"
        fp="Пълен контрол над активите зачислени към служители и процедурите за работа с тях (работно облекло, ЛПС, зачислен инвентар, механизация,
         спомагателни средства и устройства (телефон, компютър, автомобил, специализирани устройства), обхваща процедурите за работа с инвентар, механизация,
         клипчета за обучение, изпити и инструкции за работа."
        sp="Електронни изпити, чрез интерактивно меню и проследяване на резултата. Инструкции за пребиваване
         на територията на предприятие и много други."
        img="8.png"
        color="#D1D5FA"
        right="true"
      />
    </section>
    <TheClients />
    <TheFooter id="theFooter" />
  </div>
</template>

<script>
import Button from "@/components/Button";
import ImageText from "@/components/ImageText";
import TheFooter from "@/components/TheFooter";
import TheClients from "@/components/TheClients";
export default {
  name: "HomeView",
  components: {
    Button,
    ImageText,
    TheFooter,
    TheClients,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    scrollTo(id) {
      const target = document.getElementById(id);

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      window.scrollTo({
        top: target.getBoundingClientRect().top + scrollTop,
        behavior: "smooth",
      });
    },
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.home {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .home-top {
    background: url("@/assets/home/home-bg.svg") no-repeat;
    background-size: cover;
    padding: 0 10px;

    .circle {
      position: absolute;
      left: -200px;
      top: -150px;
      z-index: 1;
    }
    .circle-2 {
      position: absolute;
      right: -600px;
      top: -450px;
      z-index: 1;
    }
    .right-ribbon {
      position: absolute;
      right: -500px;
      bottom: 60px;
      z-index: 1;
    }
    .left-ribbon {
      position: absolute;
      left: -350px;
      bottom: -70px;
      z-index: 1;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      min-height: 101vh;
      max-width: 1500px;
      margin: 0 auto;
      position: relative;
    }
    header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      position: relative;
      z-index: 1;

      .button {
        font: normal normal bold 21px/49px PTSerif;
        letter-spacing: 0px;
        @include trFast();
        &:hover {
          box-shadow: 0px 3px 12px #c86813;
          letter-spacing: 1.26px;
        }
      }
    }
    .home-top-content {
      align-items: center;
      display: flex;
      flex-grow: 2;
      text-align: left;
      position: relative;
      padding: 0 10px;
      z-index: 1;
      div {
        width: 50%;
      }

      .text-box {
        h1 {
          font: normal normal bold 62px/70px PTSerif;
          strong {
            color: #c86813;
            text-decoration: underline;
            text-decoration-color: yellow;
          }
        }
        img {
          display: none;
        }
        p {
          font: normal normal normal 24px/38px PTSerif;
          letter-spacing: 0.2px;
        }
      }

      .monitor-div {
        img {
          object-fit: scale-down;
          max-width: 1200px;
          width: 55vw;
        }
      }
    }
    .learn-more {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 15px;
      position: relative;
      z-index: 1;
      img {
        object-fit: scale-down;
        height: 20px;
        width: 20px;
      }
      .button {
        display: none;
      }
    }
  }
  .home-mid {
    display: flex;
    flex-direction: column;
    max-width: 1700px;
    margin: 0 auto;
  }
}
@media all and (max-width: $sm) {
  .home {
    .home-top {
      .circle-2 {
        display: none;
      }
      .right-ribbon {
        right: -1000px;
        bottom: 10px;
      }
      .left-ribbon {
        left: -600px;
        bottom: -170px;
      }

      header {
        justify-content: center;
        .button {
          display: none;
        }
      }
      .home-top-content {
        text-align: center;
        div {
          width: 100%;
        }

        .text-box {
          padding: 0 10px;
          h1 {
            font: normal normal bold 32px/38px PTSerif;
          }
          img {
            display: block;
            width: 100%;
          }
          p {
            font: normal normal normal 17px/27px PTSerif;
            letter-spacing: 0.14px;
          }
        }
        .monitor-div {
          display: none;
        }
      }
      .learn-more {
        h1,
        img {
          display: none;
        }
        .button {
          display: block;
        }
      }
    }
  }
}
</style>

