<template>
  <main>
    <h1>Довериха ни се от</h1>
    <section>
      <a
        @mouseover="hover = i"
        @mouseleave="hover = null"
        :href="c.route"
        target="_blank"
        v-for="(c, i) in clients"
        :key="`${c}-${i}`"
      >
        <img alt="img" :src="`${require(`@/assets/clients/${c.img}`)}`" />
        <span :style="`color:${hover === i ? c.color : '#000000'};`">{{
          c.name
        }}</span>
      </a>
    </section>
  </main>
</template>

<script>
export default {
  name: "TheClients",
  data() {
    return {
      hover: null,
      clients: [
        {
          name: "Холдинг КЦМ 2000",
          img: "kcm.svg",
          route: "https://www.kcm.bg/",
          color: "#003C87",
        },
        {
          name: "МИВ Инженеринг ООД",
          img: "miv.svg",
          route: "https://mivengineering.com/",
          color: "#a50d06",
        },
        {
          name: "Монтаж Строителни Конструкции АД",
          img: "mck.png",
          route: "https://www.mck.bg/",
          color: "#E9A23E",
        },
        {
          name: "Сиенит Холдинг АД",
          img: "sienit.png",
          route: "https://sienit.com/",
          color: "#41555F",
        },
        {
          name: "Александър Електрик ЕООД",
          img: "ae.png",
          route: "https://alexanderelectric.eu/",
          color: "#00A5E3",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
main {
  max-width: 1700px;
  margin: 0 auto;
  h1 {
    margin-bottom: 1rem;
  }
  section {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 0 10px;

    a {
      background: #f2f2f2 0% 0% no-repeat padding-box;
      border-radius: 19px;
      display: flex;
      flex-direction: column;
      height: 190px;
      width: calc(20% - 10px);
      &:hover {
        img {
          transform: translateY(-15%) scale(1.2);
        }
        span {
          letter-spacing: 0.8px;
        }
      }

      img {
        height: 80%;
        object-fit: scale-down;
        mix-blend-mode: multiply;
        @include transition($transition);
      }
      span {
        font: normal normal bold 15px/20px PTSerif;
        @include transition($transition);
      }
    }
  }
}
@media all and (max-width: $sm) {
  main {
    margin: initial;
    section {
      padding: 0 0 0 10px;
      a {
        width: calc(50% - 10px);
      }
    }
  }
}
</style>