<template>
  <div
    class="button"
    :class="{ 'button--disabled': disabled }"
    @click="disabled ? null : $emit('click')"
  >
    <p>
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: ["disabled"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.button {
  align-items: center;
  background-color: #c86813;
  border-radius: 112px;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  font: normal normal 700 15px/49px PTSerif;
  font-size: $px15;
  height: 40px;
  justify-content: center;
  text-align: center;
  max-width: 320px;
  outline: none;
  width: 100%;
  @include tr();
  @include noselect();

  &.button--valid {
    background: #5a8d02 0% 0% no-repeat padding-box;
  }
  &.button--error {
    background: #c81313 0% 0% no-repeat padding-box;
  }

  p {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
  &:hover {
    .send-form {
      p {
        transform: translateX(-15%);
      }
      img {
        opacity: 1;
        left: 105%;
      }
    }
  }
  .send-form {
    display: flex;
    position: relative;
    &.visible {
      p {
        transform: translateX(-15%);
      }
      img {
        opacity: 1;
        left: 105%;
      }
    }
    p {
      font-size: 14px;
      @include trFast();
    }
    img {
      object-fit: scale-down;
      position: absolute;
      left: 0;
      opacity: 0;
      top: 50%;
      transform: translate(-25%, -50%);
      width: 22px;
      @include trFast();
    }
  }
  &.button--disabled {
    background-color: rgba($color: #c86813, $alpha: 0.3);
    cursor: default;
  }
}
</style>