<template>
  <footer>
    <ContactForm />

    <main class="footer-content" v-observe-visibility="visibilityChanged">
      <section class="wrapper">

        <transition name="slide-right" appear>
        <img  v-if="visible" alt="circle" class="circle" src="@/assets/home/circle-3.svg" />
        </transition>

        <h1>Кои сме ние?</h1>

        <div class="main-content">
          <section>
            <img
              alt="cdots-corporation-logo"
              src="@/assets/home/cdots-logo.svg"
            />
            <div class="text">
              <h1>cDots e Софтуерна инженерингова компания</h1>
              <p>
                Създаваме програми за управление на производство, машини и
                съоръжения. Системите ни изчисляват натовареността на персонала,
                калкулират заработки, надници и капацитет на машини и служители.
              </p>
              <p>
                Увеличаваме ефективността и мощностите на предприятието.
                Изчистваме административните затруднения и неработещи процеси.
              </p>
            </div>
          </section>

          <a href="https://cdots.bg/" target="_blank">
            <img
              alt="cdots-corporation-logo"
              src="@/assets/home/cdots-new.png"
            />

            <h1>
              Разгледайте и други наши решения в областта на строителството,
              търговията, медицината и др.
            </h1>
          </a>
        </div>

        <section class="cdots">
          <p>
            CDOTS.BG <br />
            2022, ВСИЧКИ ПРАВА ЗАПАЗЕНИ
          </p>
        </section>
      </section>
    </main>
  </footer>
</template>

<script>
import ContactForm from "@/components/ContactForm";
export default {
  name: "ImageText",
  components: {
    ContactForm,
  },
  props: ["h1", "p"],
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.footer-content {
  padding: 0 10px;
  .wrapper {
    max-width: 1700px;
    margin: 0 auto;
    position: relative;
  }
  .circle {
    position: absolute;
    right: -500px;
    top: -150px;
    z-index: 1;
  }
  .main-content {
    text-align: left;
    display: flex;
    justify-content: center;
    padding: 100px 0;
    position: relative;
    z-index: 1;

    section {
      width: 50%;
      img {
        margin-bottom: 20px;
      }
      .text {
        width: 85%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h1 {
          font: normal normal bold 32px/42px PTSerif;
        }
        p {
          font: normal normal normal 22px/36px PTSerif;
          letter-spacing: 0.18px;
        }
      }
    }

    a {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      text-align: center;
      @include transition($transition);

      &:hover {
        img {
          box-shadow: 0px 20px 31px rgb(0, 0, 0, 0.5);
          transform: scale(1.025);
        }
        h1 {
          color: #c86813;
          text-decoration: underline;
        }
      }

      img {
        box-shadow: 0px 10px 31px rgb(0, 0, 0, 0.5);
        border-radius: 23px;
        object-fit: scale-down;
        max-width: 600px;
        @include transition($transition);
      }
      h1 {
        font: normal normal bold 24px/31px PTSerif;
        max-width: 800px;
        margin-top: 10px;
        @include transition($transition);
      }
    }
  }
  .cdots {
    margin: 10px auto;
    position: relative;
    width: fit-content;
    z-index: 1;
    p {
      font: normal normal bold 18px/23px PTSerif;
    }
  }
}
@media all and (max-width: $sm) {
  .footer-content {
    .circle {
      bottom: 0;
      position: absolute;
      right: -900px;
      top: 500px;
      z-index: 1;
    }

    .main-content {
      flex-direction: column;
      padding: 50px 0;

      section {
        text-align: center;
        width: 100%;
        img {
          max-width: 600px;
          width: 100%;
        }
        .text {
          width: 100%;
        }
      }
      a {
        margin: 50px auto 0 auto;
        padding: 0 10px;
        img {
          max-width: 500px;
          width: 100%;
        }
      }
    }
  }
}
</style>